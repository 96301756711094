import './_why-us.scss'
import Swiper from "swiper";
import {Pagination} from "swiper/modules";
import 'swiper/css';
import 'swiper/css/pagination';
import Masonry from 'masonry-layout';
import {CountUp} from 'countup.js/dist/countUp.min.js';

const whyUs = function () {
	const blocks = document.querySelectorAll('section.why-us')

	function isElementInViewport(el) {
		var rect = el.getBoundingClientRect();
		return (
			rect.top - 100 <= 0 && 
			rect.bottom - ( window.screen.height - 200 ) >= 0
		);
	}

	function handleScroll( targetDiv, callback ) {
		if ( isElementInViewport( targetDiv ) ) {
			if ( callback && typeof callback === 'function' ) {
				callback();
			}
		}
	}

	function countEmUp( elements, isCounted ){
		return () => {
			
			if (isCounted) {
				return;
			}
			elements.forEach(item => {
				if ( parseInt( item.innerHTML ) === 0 ){
					let countUp = new CountUp(item, item.dataset.value, {
						useGrouping: false,
						separator: '',
					});
					countUp.start();
				}
			});
		};
	}

    for (const block of blocks) {
        let tabButtons = block.querySelectorAll('.tab-text');
        let tabs = block.querySelectorAll('.tab');
		let isCounted = false;
		const description = block.querySelector( '.top-section .description' );

		const initMasonry = ( grid, useTimeout = 0 ) => {
			const masonryOptions = {
				columnWidth: '.review',
				itemSelector: '.masonry',
				percentPosition: true,
				gutter: 20,
			};
		  
			return useTimeout > 0
				? setTimeout( () => new Masonry( grid, masonryOptions ), useTimeout )
				: new Masonry( grid, masonryOptions );
		};

        tabs.forEach(tab => {
            const swiperObject = tab.querySelector('.tab-swiper');
            new Swiper(swiperObject, {
                modules: [Pagination],
                speed: 400,
                spaceBetween: 20,
                slidesPerView: 1.1,
                pagination: {el: tab.querySelector('.swiper-pagination')},
            });
            tab.classList.add('active');
            if (tab.querySelector('.reviews')) {
				initMasonry( tab.querySelector( '.reviews' ), 500 );
            }
            if (tab.querySelector('.two-blocks')  && !isCounted ) {
				window.addEventListener('scroll', () => {
                    handleScroll(block, countEmUp(tab.querySelectorAll('.countup'), isCounted));
                });
            }
            if (tab.dataset.tab !== '0') {
                tab.classList.remove('active');
            }
        });

        tabButtons.forEach(button => {
            button.addEventListener('click', () => {
                let index = button.dataset.tab;
				let activeTab = tabs[0];
                tabButtons.forEach(btn => {
                    btn.classList.remove('active');
                });
                tabs.forEach(tab => {
                    tab.classList.remove('active');
                    if (tab.dataset.tab === index) {
                        tab.classList.add('active');
                        button.classList.add('active');
						activeTab = tab;
                    }
                    if ( activeTab.querySelector('.two-blocks') ) {
                        const options = {
                            useGrouping: false,
                            separator: '',
                        };
                        tab.querySelectorAll('.countup').forEach(item => {
                            let countUp = new CountUp(item, item.dataset.value, options);
                            countUp.start();
                        });
                    }
					if ( activeTab.querySelector('.reviews') ) {
						initMasonry( activeTab.querySelector( '.reviews' ) );
						description.classList.add( 'hidden' );
					} else {
						description.classList.remove( 'hidden' );
					}
                })
            });
        });
    }
};
document.addEventListener('DOMContentLoaded', function () {
    whyUs()
}, false);

window.addEventListener('acf/loaded/why-us', function (e) {
    whyUs()
});